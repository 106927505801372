@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700&family=DM+Serif+Display&family=Geo&family=Karla:wght@200;300;400;600&family=Michroma&family=Sulphur+Point:wght@300;400;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Sulphur Point", sans-serif;
}

body {
  background-color: #fffbf5;
}

.container-gallery-img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-image {
  width: 300px;
  height: 400px;
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.category-image > img {
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.category-image > img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.modal-container {
  background-color: #212121ed;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 444;
}

.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content > img {
  width: 800px;
  height: 550px;
  object-fit: cover;
}

.modal-caption {
  padding-top: 10px;
  color: #fffbf5;
  font-size: 20px;
}

button {
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  z-index: 99999;
}

.btn-previous {
  font-size: 20px;
  left: 10%;
}

.btn-next {
  font-size: 20px;
  right: 10%;
}

.btn-close {
  font-size: 30px;
  top: 15%;
  right: 10%;
}

@media (max-width: 768px) {
  .category-image {
    width: 85%;
    height: 55%;
  }
  .btn-previous {
    font-size: 20px;
    left: 10%;
    bottom: 5%;
  }

  .btn-next {
    font-size: 20px;
    right: 10%;
    bottom: 5%;
  }

  .modal-content > img {
    width: 300px;
    height: 550px;
    object-fit: contain;
  }
  .btn-close {
    font-size: 30px;
    top: 5%;
    right: 10%;
  }
}

@media screen and (min-width: 1200px) {

}

.container_home {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}
.row_home {
  width: 80%;
  height: 350px;
  margin: auto;
  margin-bottom: 100px;
}
.row_home a {
  text-decoration: none;
  display: block;
  color: black;
}
.row_home img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.image_home_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffbf5cb;
  color: #000000;
  font-family: "Karla", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}
.image_home_overlay > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}
.image_home_overlay:hover {
  opacity: 1;
}
.image_home_overlay:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.image_home_title {
  text-transform: uppercase;
  font-size: 6em;
  font-weight: bold;
  font-family: "Karla", sans-serif;
  text-align: center;
}

@media (max-width: 768px) {
  .image_home_title {
    font-size: 2em;
    padding-bottom: 30px;
  }
  .row_home {
    margin-bottom: 50px;
    height: 150px;
  }
  .row_home img {
    margin-bottom: 0;
    height: 100%;
  }
  .image_home_overlay {
    background: linear-gradient(0deg, rgb(253, 247, 239) 0%, rgba(255, 243, 226, 0.616) 40%, rgba(255, 255, 255, 0.261) 60%);
    color: rgb(59, 59, 59);
    opacity: 1;
    height: 150px;
    margin-bottom: 0;
  }
  .container_home {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
}

.slider-home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

.slider-home {
  width: 80%;
  height: 50vh;
  margin: auto;
  margin-top: 80px;
}
@media (min-width: 768px) {
  .slider-home {
    width: 80%;
    height: 80vh;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}


.estudio-section {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 50px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.estudio-section h1 {
  font-size: 1.5rem;
  text-align: left;
}

.estudio-text {
  width: 100%;
  padding: 50px;
  font-size: 1rem;
  line-height: 45px;
}
.estudio-image {
  width: 90%;
  margin: auto;
}
.estudio-image img {
  width: 100%;
}
@media (min-width: 1200px) {
  .estudio-section {
    width: 100%;
  }

  .estudio-section h1 {
    font-size: 2.5rem;
    margin-bottom: 50px;
    text-align: center;
  }

  .estudio-article {
    width: 90%;
    font-size: 1.2rem;
    line-height: 35px;
    margin-bottom: 100px;
    height: auto;
    text-align: left;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .estudio-text {
    width: 50%;
    padding: 50px;
  }

  .estudio-image {
    width: 50%;
  }
}

.projects-section {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}

.projects-gallery-section {
  width: 100%;
  height: 100px;
  background-color: rgba(250, 210, 165, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}

.projects-article {
  width: 100%;
  height: 100px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

.projects-article h1 {
  font-size: 1.5em;
}

.projects-article > h3 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.projects-pagination {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 60px;
}

.projects-pagination a {
  font-size: 0.9em;
  color: black;
  text-decoration: none;
}
.projects-pagination-prev {
  text-align: left;
}
.projects-pagination-next {
  text-align: right;
}
.projects-article h1 {
  font-size: 1.5em;
}
@media (min-width: 768px) {
  .projects-pagination a {
    font-size: 1.2em;
    color: black;
    text-decoration: none;
  }
  .projects-pagination {
    width: 80%;
  }
}

.image-projects img {
  width: 100%;
  object-fit: cover;
}

.row-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image-projects {
  width: 500px;
  position: relative;
  margin: 0 20px 40px 0;
  height: 250px;
}

.image-projects-img {
  height: 250px;
  display: block;
}

.image-projects-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: #fffbf5cb;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image-projects-overlay > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.image-projects-overlay:hover {
  opacity: 1;
}

.image-projects-overlay:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.image-projects-title {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}
.image-projects-subtitle {
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .image-projects {
    width: 300px;
    margin: 0 0 40px 0;
  }
  .image-projects-overlay {
    background: linear-gradient(0deg, rgb(253, 247, 239) 0%, rgba(255, 243, 226, 0.616) 40%, rgba(255, 255, 255, 0.261) 60%);
    color: rgb(59, 59, 59);
    opacity: 1;

  }
  .image-projects-overlay > * {
    -webkit-transform: none;
            transform: none;
    transition: none;
  }

  .image-projects-title {
  text-transform: uppercase;
  font-size: 1.2em;
  padding-top: 120px;
  font-weight: bold;
  text-align: center;
}

}


.nav-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fffbf5;
  display: flex;
  z-index: 100;
}

.navbar-home-active {
  width: 90%;
  height: 80px;
  margin: auto;
}

.nav-logo-home-active {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 1.2rem;
}

.nav-logo-home-active a {
  text-decoration: none;
  color: black;
  padding-left: 30px;
}

.menu-bars-home-active {
  display: flex;
  font-size: 1rem;
  position: absolute;
  top: 40%;
  padding-left: 20px;
}

.menu-bars-home-active a {
  color: black;
}

/* TOGGLE NAVBAR */

.menu-bars-close {
  margin-left: 2rem;
  font-size: 1rem;
  background: none;
  color: black;
  display: block;
}

.nav-menu {
  background-color: #fffbf5;
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 1s;
}

.nav-menu.active {
  left: 0;
  transition: 0.6s;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1f1f1f;
  color: white;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fffbf5;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .nav-logo-home-active {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 1.5rem;
  }
  .nav-logo-home-active a {
    padding-left: 0;
  }
}

.publicaciones-section h1 {
  font-size: 1.2rem;
  margin-bottom: 50px;
  text-align: center;
}

.publicaciones-section {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 20px;
}

.publicaciones-article p {
  font-size: 1em;
  padding: 0 30px;
  line-height: 45px;
  text-align: center;
}

.publicaciones-article {
  width: 80%;
  height: 100%;
  margin: auto;
}

.publicaciones-links {
  width: 100%;
  height: 500px;
}

.publicaciones-links img {
  width: 100px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.publicaciones-links li:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.publicaciones-links li {
  list-style-type: none;
  float: left;
  width: calc(100% / 2);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media (min-width: 768px) {
  .publicaciones-section h1 {
    font-size: 2rem;
    text-align: center;
  }

  .publicaciones-links {
    margin-top: 50px;
  }

  .publicaciones-links img {
    width: 150px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  .publicaciones-article p {
    font-size: 1.2em;
    padding: 0 30px;
    line-height: 45px;
    text-align: center;
  }
  .publicaciones-links li {
    width: calc(100% / 3);
    height: 120px;
  }
}

.contacto-article {
  text-align: center;
  letter-spacing: 0.2rem;
  margin: 150px 0 80px 0;
}

.contacto-article p {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.contacto-section {
  width: 100%;
  height: 600px;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.contacto-section h1 {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.contacto-separador{
  width: 30%;
  margin: auto;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .contacto-section {
    width: 85%;
    height: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .contacto-article {
    text-align: left;
    letter-spacing: 0.1rem;
  }

.contacto-section h1{
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .contacto-article p {
    font-size: 1rem;
    padding-bottom: 30px;
  }
}

.clientes-section h1 {
  font-size: 1.2rem;
  margin-bottom: 50px;
  text-align: center;
}

.clientes-section {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 20px;
}

.clientes-article p {
  font-size: 1em;
  padding: 0 30px;
  line-height: 45px;
  text-align: center;
}

.clientes-article {
  width: 80%;
  height: 100%;
  margin: auto;
}

.clientes-links {
  width: 100%;
  height: 300px;
}

.clientes-links img {
  width: 100px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.clientes-links li:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.clientes-links li {
  list-style-type: none;
  float: left;
  width: calc(100% / 2);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.clientes-links-bo{
  width: 60px!important;
}

@media (min-width: 768px) {
  .clientes-section h1 {
    font-size: 2rem;
    text-align: center;
  }

  .clientes-links {
    margin-top: 50px;
  }

  .clientes-links img {
    width: 150px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  .clientes-links li {
  width: calc(100% / 3);
  height: 120px;
}
}

  .footer {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
  }

  .hr-footer {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .container-footer {
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
  }

  .footer-logo-img {
    display: none;
  }

  .footer-text {
    font-family: "Sulphur Point", sans-serif;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-text p {
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding-top: 1rem;
  }

  .footer-text-right {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0.1rem;
    font-family: "Sulphur Point", sans-serif;
    text-align: right;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-size: 1rem;
  }

  .footer-text-right li {
    list-style-type: none;
  }

  .social-icons {
    width: 25%;
    padding-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  .social-icons a {
    padding: 15px;
    font-size: 20px;
    color: black;
  }


@media (min-width: 1200px) {
  .footer {
    width: 100%;
    height: 250px;
    margin-top: 50px;
    position: relative;
    bottom: 0;
  }

  .hr-footer {
    width: 90%;
    margin: auto;
  }

  .container-footer {
    width: 80%;
    height: 200px;
    margin: auto;
    display: flex;
  }

  .footer-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 30px;
  }

  .footer-logo img {
    width: 200px;
    height: 200px;
    display: block;
    object-fit: cover;
    margin: left;
    margin-bottom: 90px;
  }

  .footer-text {
    font-family: "Sulphur Point", sans-serif;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-text p {
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    padding-top: 10px;
  }

  .footer-text-right {
    width: 100%;
    letter-spacing: 0.1rem;
    font-family: "Sulphur Point", sans-serif;
    text-align: right;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    line-height: 30px;
    font-size: 1.2rem;
  }

  .footer-text-right li {
    list-style-type: none;
  }

  .social-icons {
    width: 25%;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  .social-icons a {
    font-size: 20px;
    color: black;
  }
}



.cookie-banner {
  width: 100%;
  padding: 36px;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.cookie-banner-text {
  color: white;
  margin-bottom: 36px;
  line-height: 20px;
  text-align: left;
  padding: 0 12px;
}

.cookie-banner-buttons {
  display: flex;
}

.cookie-banner-buttons span {
  width: -webkit-max-content;
  width: max-content;
  background: white;
  padding: 10px 18px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 16px;
}

.cookie-banner-buttons span:hover {
  background: rgb(104, 104, 104);
  color: white;
}

@media (min-width: 768px) {
  .cookie-banner-buttons span {
    width: -webkit-max-content;
    width: max-content;
    background: white;
    padding: 10px 24px;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 16px;
  }
}

