  .footer {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
  }

  .hr-footer {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .container-footer {
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
  }

  .footer-logo-img {
    display: none;
  }

  .footer-text {
    font-family: "Sulphur Point", sans-serif;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-text p {
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding-top: 1rem;
  }

  .footer-text-right {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0.1rem;
    font-family: "Sulphur Point", sans-serif;
    text-align: right;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-size: 1rem;
  }

  .footer-text-right li {
    list-style-type: none;
  }

  .social-icons {
    width: 25%;
    padding-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  .social-icons a {
    padding: 15px;
    font-size: 20px;
    color: black;
  }


@media (min-width: 1200px) {
  .footer {
    width: 100%;
    height: 250px;
    margin-top: 50px;
    position: relative;
    bottom: 0;
  }

  .hr-footer {
    width: 90%;
    margin: auto;
  }

  .container-footer {
    width: 80%;
    height: 200px;
    margin: auto;
    display: flex;
  }

  .footer-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 30px;
  }

  .footer-logo img {
    width: 200px;
    height: 200px;
    display: block;
    object-fit: cover;
    margin: left;
    margin-bottom: 90px;
  }

  .footer-text {
    font-family: "Sulphur Point", sans-serif;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-text p {
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    padding-top: 10px;
  }

  .footer-text-right {
    width: 100%;
    letter-spacing: 0.1rem;
    font-family: "Sulphur Point", sans-serif;
    text-align: right;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    line-height: 30px;
    font-size: 1.2rem;
  }

  .footer-text-right li {
    list-style-type: none;
  }

  .social-icons {
    width: 25%;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  .social-icons a {
    font-size: 20px;
    color: black;
  }
}


