.clientes-section h1 {
  font-size: 1.2rem;
  margin-bottom: 50px;
  text-align: center;
}

.clientes-section {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 20px;
}

.clientes-article p {
  font-size: 1em;
  padding: 0 30px;
  line-height: 45px;
  text-align: center;
}

.clientes-article {
  width: 80%;
  height: 100%;
  margin: auto;
}

.clientes-links {
  width: 100%;
  height: 300px;
}

.clientes-links img {
  width: 100px;
  filter: grayscale(100%);
}

.clientes-links li:hover {
  transform: scale(1.1);
}

.clientes-links li {
  list-style-type: none;
  float: left;
  width: calc(100% / 2);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.clientes-links-bo{
  width: 60px!important;
}

@media (min-width: 768px) {
  .clientes-section h1 {
    font-size: 2rem;
    text-align: center;
  }

  .clientes-links {
    margin-top: 50px;
  }

  .clientes-links img {
    width: 150px;
    filter: grayscale(100%);
  }
  .clientes-links li {
  width: calc(100% / 3);
  height: 120px;
}
}
