.image-projects img {
  width: 100%;
  object-fit: cover;
}

.row-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image-projects {
  width: 500px;
  position: relative;
  margin: 0 20px 40px 0;
  height: 250px;
}

.image-projects-img {
  height: 250px;
  display: block;
}

.image-projects-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: #fffbf5cb;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image-projects-overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image-projects-overlay:hover {
  opacity: 1;
}

.image-projects-overlay:hover > * {
  transform: translateY(0);
}

.image-projects-title {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}
.image-projects-subtitle {
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .image-projects {
    width: 300px;
    margin: 0 0 40px 0;
  }
  .image-projects-overlay {
    background: linear-gradient(0deg, rgb(253, 247, 239) 0%, rgba(255, 243, 226, 0.616) 40%, rgba(255, 255, 255, 0.261) 60%);
    color: rgb(59, 59, 59);
    opacity: 1;

  }
  .image-projects-overlay > * {
    transform: none;
    transition: none;
  }

  .image-projects-title {
  text-transform: uppercase;
  font-size: 1.2em;
  padding-top: 120px;
  font-weight: bold;
  text-align: center;
}

}

