.estudio-section {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 50px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.estudio-section h1 {
  font-size: 1.5rem;
  text-align: left;
}

.estudio-text {
  width: 100%;
  padding: 50px;
  font-size: 1rem;
  line-height: 45px;
}
.estudio-image {
  width: 90%;
  margin: auto;
}
.estudio-image img {
  width: 100%;
}
@media (min-width: 1200px) {
  .estudio-section {
    width: 100%;
  }

  .estudio-section h1 {
    font-size: 2.5rem;
    margin-bottom: 50px;
    text-align: center;
  }

  .estudio-article {
    width: 90%;
    font-size: 1.2rem;
    line-height: 35px;
    margin-bottom: 100px;
    height: auto;
    text-align: left;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .estudio-text {
    width: 50%;
    padding: 50px;
  }

  .estudio-image {
    width: 50%;
  }
}
