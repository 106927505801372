.cookie-banner {
  width: 100%;
  padding: 36px;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.cookie-banner-text {
  color: white;
  margin-bottom: 36px;
  line-height: 20px;
  text-align: left;
  padding: 0 12px;
}

.cookie-banner-buttons {
  display: flex;
}

.cookie-banner-buttons span {
  width: max-content;
  background: white;
  padding: 10px 18px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 16px;
}

.cookie-banner-buttons span:hover {
  background: rgb(104, 104, 104);
  color: white;
}

@media (min-width: 768px) {
  .cookie-banner-buttons span {
    width: max-content;
    background: white;
    padding: 10px 24px;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 16px;
  }
}
