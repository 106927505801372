.slider-home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

.slider-home {
  width: 80%;
  height: 50vh;
  margin: auto;
  margin-top: 80px;
}
@media (min-width: 768px) {
  .slider-home {
    width: 80%;
    height: 80vh;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

