.container_home {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}
.row_home {
  width: 80%;
  height: 350px;
  margin: auto;
  margin-bottom: 100px;
}
.row_home a {
  text-decoration: none;
  display: block;
  color: black;
}
.row_home img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.image_home_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffbf5cb;
  color: #000000;
  font-family: "Karla", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}
.image_home_overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}
.image_home_overlay:hover {
  opacity: 1;
}
.image_home_overlay:hover > * {
  transform: translateY(0);
}
.image_home_title {
  text-transform: uppercase;
  font-size: 6em;
  font-weight: bold;
  font-family: "Karla", sans-serif;
  text-align: center;
}

@media (max-width: 768px) {
  .image_home_title {
    font-size: 2em;
    padding-bottom: 30px;
  }
  .row_home {
    margin-bottom: 50px;
    height: 150px;
  }
  .row_home img {
    margin-bottom: 0;
    height: 100%;
  }
  .image_home_overlay {
    background: linear-gradient(0deg, rgb(253, 247, 239) 0%, rgba(255, 243, 226, 0.616) 40%, rgba(255, 255, 255, 0.261) 60%);
    color: rgb(59, 59, 59);
    opacity: 1;
    height: 150px;
    margin-bottom: 0;
  }
  .container_home {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
}
