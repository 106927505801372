.projects-section {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}

.projects-gallery-section {
  width: 100%;
  height: 100px;
  background-color: rgba(250, 210, 165, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}

.projects-article {
  width: 100%;
  height: 100px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

.projects-article h1 {
  font-size: 1.5em;
}

.projects-article > h3 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.projects-pagination {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 60px;
}

.projects-pagination a {
  font-size: 0.9em;
  color: black;
  text-decoration: none;
}
.projects-pagination-prev {
  text-align: left;
}
.projects-pagination-next {
  text-align: right;
}
.projects-article h1 {
  font-size: 1.5em;
}
@media (min-width: 768px) {
  .projects-pagination a {
    font-size: 1.2em;
    color: black;
    text-decoration: none;
  }
  .projects-pagination {
    width: 80%;
  }
}
