.contacto-article {
  text-align: center;
  letter-spacing: 0.2rem;
  margin: 150px 0 80px 0;
}

.contacto-article p {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.contacto-section {
  width: 100%;
  height: 600px;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.contacto-section h1 {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.contacto-separador{
  width: 30%;
  margin: auto;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .contacto-section {
    width: 85%;
    height: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .contacto-article {
    text-align: left;
    letter-spacing: 0.1rem;
  }

.contacto-section h1{
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .contacto-article p {
    font-size: 1rem;
    padding-bottom: 30px;
  }
}
