@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700&family=DM+Serif+Display&family=Geo&family=Karla:wght@200;300;400;600&family=Michroma&family=Sulphur+Point:wght@300;400;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Sulphur Point", sans-serif;
}

body {
  background-color: #fffbf5;
}

.container-gallery-img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-image {
  width: 300px;
  height: 400px;
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.category-image > img {
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.category-image > img:hover {
  transform: scale(1.05);
}

.modal-container {
  background-color: #212121ed;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 444;
}

.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content > img {
  width: 800px;
  height: 550px;
  object-fit: cover;
}

.modal-caption {
  padding-top: 10px;
  color: #fffbf5;
  font-size: 20px;
}

button {
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  z-index: 99999;
}

.btn-previous {
  font-size: 20px;
  left: 10%;
}

.btn-next {
  font-size: 20px;
  right: 10%;
}

.btn-close {
  font-size: 30px;
  top: 15%;
  right: 10%;
}

@media (max-width: 768px) {
  .category-image {
    width: 85%;
    height: 55%;
  }
  .btn-previous {
    font-size: 20px;
    left: 10%;
    bottom: 5%;
  }

  .btn-next {
    font-size: 20px;
    right: 10%;
    bottom: 5%;
  }

  .modal-content > img {
    width: 300px;
    height: 550px;
    object-fit: contain;
  }
  .btn-close {
    font-size: 30px;
    top: 5%;
    right: 10%;
  }
}
