.nav-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fffbf5;
  display: flex;
  z-index: 100;
}

.navbar-home-active {
  width: 90%;
  height: 80px;
  margin: auto;
}

.nav-logo-home-active {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 1.2rem;
}

.nav-logo-home-active a {
  text-decoration: none;
  color: black;
  padding-left: 30px;
}

.menu-bars-home-active {
  display: flex;
  font-size: 1rem;
  position: absolute;
  top: 40%;
  padding-left: 20px;
}

.menu-bars-home-active a {
  color: black;
}

/* TOGGLE NAVBAR */

.menu-bars-close {
  margin-left: 2rem;
  font-size: 1rem;
  background: none;
  color: black;
  display: block;
}

.nav-menu {
  background-color: #fffbf5;
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 1s;
}

.nav-menu.active {
  left: 0;
  transition: 0.6s;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1f1f1f;
  color: white;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fffbf5;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .nav-logo-home-active {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 1.5rem;
  }
  .nav-logo-home-active a {
    padding-left: 0;
  }
}
