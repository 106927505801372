.publicaciones-section h1 {
  font-size: 1.2rem;
  margin-bottom: 50px;
  text-align: center;
}

.publicaciones-section {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 20px;
}

.publicaciones-article p {
  font-size: 1em;
  padding: 0 30px;
  line-height: 45px;
  text-align: center;
}

.publicaciones-article {
  width: 80%;
  height: 100%;
  margin: auto;
}

.publicaciones-links {
  width: 100%;
  height: 500px;
}

.publicaciones-links img {
  width: 100px;
  filter: grayscale(100%);
}

.publicaciones-links li:hover {
  transform: scale(1.1);
}

.publicaciones-links li {
  list-style-type: none;
  float: left;
  width: calc(100% / 2);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media (min-width: 768px) {
  .publicaciones-section h1 {
    font-size: 2rem;
    text-align: center;
  }

  .publicaciones-links {
    margin-top: 50px;
  }

  .publicaciones-links img {
    width: 150px;
    filter: grayscale(100%);
  }
  .publicaciones-article p {
    font-size: 1.2em;
    padding: 0 30px;
    line-height: 45px;
    text-align: center;
  }
  .publicaciones-links li {
    width: calc(100% / 3);
    height: 120px;
  }
}
